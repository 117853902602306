<template>
  <div>
    <b-card>
     <filter-swapper v-show="!loading.skeletonTable" id="listToAssign_swapper" :trigger="selectedRows.length === 0" :buttons="buttons" :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render :form.sync="form" :key="keyFormRender" :fields="fields" @send="filterList" :buttonSend="buttonSend" ref="formFilter"
            containerButtonsClass="col-sm-12 col-md-7 col-lg-4 col-xl-3 mt-2" class="mb-2">
            <template #buttons>
              <b-button variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-show="!loading.skeletonTable">
        <table-render :key="tableRender" :schema="schema" :rows="rows" :actions="actions" :showCheckboxes="true" :selectedRows.sync="selectedRows" :loading="loading.loadingTable" ></table-render>
        <pagination :pagination="pagination" :noDigits="false" :showSize="true"/>
      </div>
      <b-skeleton type="input" v-if="loading.skeletonTable" class="mt-2 spacing-label-field" style="margin-bottom: 2.15rem;"/>
      <div class="table-render-skeleton" v-if="loading.skeletonTable">
        <b-skeleton-table
          :rows="pagination.limit || 5"
          :columns="schema.length || 10"
          :table-props="{ }"/>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'list-to-assign',
  props: ['deliveries', 'skeletonTable'],
  data() {
    return {
      selectedRows: [],
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      buttonSend: { title: 'Buscar', icon: 'SearchIcon', color: 'warning' },
      keyFormRender: 0,
      form: {},
      filters: {},
      fields: [],
      buttons: [],
      schema: [],
      tableRender: 0,
      rows: [],
      actions: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 10
      },
      loading: {
        deliveries: true,
        organizations: true,
        shippersByOrganization: true,
        total: true,
        first: true,
        skeletonTable: false,
        loadingTable: false
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      organizations: 'getOrganizationsGlobal',
      shippersByOrganization: 'getShippersByOrganizationSimplified',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    mySession () {
      this.session_user = {
        country: this.mySession.country, 
        role: this.mySession.role,
        organization: this.mySession.organization ? this.mySession.organization : null,
        shipper: this.mySession.shipper ? this.mySession.shipper : null
      }
      this.setInitialData()
    },
    deliveries () {
      this.selectedRows = []
      if (!this.loading.loadingTable) this.form = {}
      if (!!this.deliveries) {
        this.rows = this.deliveries.rows
        this.pagination.total = this.deliveries.meta.pagination.total
        this.tableRender++
        if (this.loading.loadingTable) this.loading.loadingTable = false
      }
    },
    organizations () {
      this.setSelectOptions('organization_id', { options: this.organizations })
    },
    shippersByOrganization () {
      this.setSelectOptions('shipper_id', { options: this.shippersByOrganization })
    },
    'pagination.page' () {
      this.getDeliveries()
    },
    'pagination.limit' () {
      this.getDeliveries()
    },
    skeletonTable () {
      this.loading.skeletonTable = this.skeletonTable
    },
    generalLoading: {
      handler () {
        this.loading.deliveries = !!this.generalLoading.getDeliveriesFlash
        this.loading.shippersByOrganization = !!this.generalLoading.shippersByOrganization
        this.loading.organizations = !!this.generalLoading.getOrganizationsGlobal
      },
      deep: true
    },
    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    }
  },
  mounted () {
    if (this.mySession.id) this.setInitialData()
  },
  methods: {
    setInitialData() {
      if (['admin', 'superadmin'].includes(this.session_user.role)) {
        this.fields = [
          { fieldType: 'FieldSelect', name: 'organization_id', label: 'Organización', containerClass: 'container-info col-sm-12 col-md-2', change: this.changeOrganization },
          { fieldType: 'FieldSelect', name: 'shipper_id', label: 'Empresa', useLabel: true, containerClass: 'container-info col-sm-12 col-md-2', dependency: 'organization_id' },
          { fieldType: 'FieldInput', label: 'N° de ticket, de envío', name: 'imported_id', containerClass: 'col-md-2 container-info col-sm-12' },
          { fieldType: 'FieldDatepicker', label: 'Fecha de búsqueda', name: 'find_date', range: true, clearable: true, containerClass: 'container-info col-sm-12 col-md-3'}
        ]
      } else if (['marketplace'].includes(this.session_user.role)) {
        this.fields = [
          { fieldType: 'FieldSelect', name: 'shipper_id', label: 'Empresa', useLabel: true, containerClass: 'container-info col-sm-12 col-md-2'},
          { fieldType: 'FieldInput', label: 'N° de ticket, de envío', name: 'imported_id', containerClass: 'col-md-2 container-info col-sm-12' },
          { fieldType: 'FieldDatepicker', label: 'Fecha de búsqueda', name: 'find_date', range: true, clearable: true, containerClass: 'container-info col-sm-12 col-md-3'}
        ]
        this.organization = this.session_user.organization
      } else if (['seller', 'ecommerce'].includes(this.session_user.role)) {
        this.fields = [
          { fieldType: 'FieldInput', label: 'N° de ticket, de envío', name: 'imported_id', containerClass: 'col-md-2 container-info col-sm-12' },
          { fieldType: 'FieldDatepicker', label: 'Fecha de búsqueda', name: 'find_date', range: true, clearable: true, containerClass: 'container-info col-sm-12 col-md-3'}
        ]
      }
      this.schema = [
        {label: 'Seller', key: 'shipper_name', sortable: true},
        {label: 'Estado', key: 'status_name', sortable: true, class: ['text-center']},
        {label: 'N° de envío', key: 'imported_id', sortable: true},
        {label: 'Cliente', key: 'customer', sortable: true},
        {label: 'Comuna', key: 'place', sortable: true},
        {label: 'Fecha creación', key: 'created_at', sortable: true, wrap: true},
        {label: 'Acciones', key: 'actions', class: 'text-center', style: { width: '10%'}}
      ]
      this.actions = [
        {action: id => this.confirmCancelDelivery(id), icon: 'XCircleIcon', color: 'danger', text: 'Cancelar envío'}
      ]
      this.buttons = [{ name: 'cancelDelivery', text: 'Cancelar envíos', color: 'danger', icon: 'XCircleIcon', action: this.confirmCancelDeliveries }]
      if (!!this.organizations && this.organizations.length !== 0) { 
        this.setSelectOptions('organization_id', { options: this.organizations })
      }
    },
    filterList (data) {
      this.filters = {}
      Object.keys(data).map(key => {
        switch (key) {
        case 'organization_id':
          break
        case 'find_date':
          if (data[key] !== null) {
            this.filters['created_at_from'] = this.$options.filters.moment(data[key].start)
            this.filters['created_at_to'] = this.$options.filters.moment(data[key].end)
          }
          break
        default:
          if (data[key] !== null) this.filters[key] = data[key].id || data[key]
          break
        }
      })
      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.getDeliveries()
    },
    cleanFilter (e) {
      this.form = {}
      this.filters = {}
      this.getDeliveries()
    },
    changeOrganization (name, value) {
      const index = this.fields.findIndex(el => el.name === 'shipper_id')
      if (value === null) {
        this.form['shipper_id'] = null
        delete this.form['shipper_id']
        this.fields[index].options = []
        return
      }
      const params = {
        organization_id: value.id
      }
      this.fields[index].useSkeleton = true
      this.$store.dispatch('fetchService', { name: 'getShippersByOrganizationSimplified', queryParams: {}, params, onSuccess: () => this.fields[index].useSkeleton = false })
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },
    getDeliveries() {
      this.loading.loadingTable = true
      const queryParams = {
        ...this.filters,
        delivery_type: 'flash',
        country_code: this.session_user?.country?.code,
        limit: this.pagination.limit,
        page: this.pagination.page,
        flash_status: 'created'
      }
      this.$emit('updateList', queryParams, false)
    },
    
    confirmCancelDelivery(_id) {
      this.$yesno(this.$t('msg-pregunta-eliminar-elemento'), () => this.cancelDelivery(_id))
    },
    cancelDelivery(_id) {
      const { shipper, id } = this.rows.filter(delivery => delivery.id === _id)[0]
      const data = { 
        name: 'cancelDeliveriesFlash',
        params: { shipper_id: shipper.id, delivery_id: id },
        onSuccess: () => { this.$success(this.$t('msg-exito-eliminar-elemento')); setTimeout('2000', this.getDeliveries()) }
      }
      this.$store.dispatch('fetchService', data)
    },

    confirmCancelDeliveries() {
      this.$yesno(this.$t('msg-pregunta-eliminar-seleccionados'), this.cancelMultipleDeliveries)
    },

    cancelMultipleDeliveries() {
      const services = this.selectedRows.map(_id => {
        const { shipper, id } = this.rows.filter(delivery => delivery.id === _id)[0]
        const params = { shipper_id: shipper.id, delivery_id: id }
        return ({ name: 'cancelDeliveriesFlash', params})
      })
      this.$store.dispatch('fetchMultipleServices', { services, showPackSuccess: true, onSuccess: setTimeout('2000', this.getDeliveries()) })
    }
  }
}
</script>